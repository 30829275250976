import React, { useState, useEffect } from "react";
import { ROUTER } from "../../config";
import PlayersContainer from "../../components/players-container";
import { API, API_ROUTER } from "../../api";
import { Link, useParams, useHistory } from "react-router-dom";
import s from "./registrationTournament.module.scss";
import TeamContainer from "../../components/team-container";
import { getUserData } from "../../redux/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { CircularProgress } from "@material-ui/core";
import { toast } from "react-toastify";

const RegistrationTournament = ({ userData }) => {
  const [countPlayers, setCountPlayers] = useState(0);
  const [membersRoster, setMembersRoster] = useState(null);
  const [teamRosterMembers, setTeamRosterMembers] = useState([]);
  const [data, setData] = useState(null);
  const [sizeTeam, setSizeTeam] = useState(0);
  let { id } = useParams();
  const [userTeams, setUserTeams] = useState(null);
  const [teamId, setTeamId] = useState();
  const [teamName, setTeamName] = useState();
  const [currentTeam, setCurrentTeam] = useState(false);
  const [flag, setFlag] = useState();
  const [requestSuccess, setRequestSuccess] = useState(false);
  const tournamentGameId = data?.game?.id;
  const [playersCount, setPlayersCount] = useState(0);
  const [rosterId, setRosterId] = useState();
  const history = useHistory();

  useEffect(() => {
    API.request({
      ...API_ROUTER.tournaments.getCurrentTournament,
      pathKeys: {
        tournamentId: id,
      },
    })
      .then((res) => {
        setData(res);
        setSizeTeam(+res.size.split("x")[0]);
        /* console.log(res); */
      })
      .catch((err) => console.log(err));

    API.request({
      ...API_ROUTER.teams.getTeamByRole,
      pathKeys: {
        userId: userData?.id,
        role: "owner",
      },
    })
      .then((res) => {
        setUserTeams(res);
        setRequestSuccess(true);
      })
      .catch((err) => console.log(err));
    API.request({
      ...API_ROUTER.tournaments.getPrimaryMembers,
    })
      .then((res) => {
        setMembersRoster(res);
        /* console.log(res); */
      })
      .catch((err) => console.log(err));
  }, [userData?.id, id]);

  const submitTeamRoster = (tournamentId, rosterId) => {
    console.log(membersRoster);
    API.request({
      ...API_ROUTER.tournaments.joinAsRoster,
      pathKeys: {
        tournamentId: tournamentId,
        rosterId: rosterId,
      },
    })
      .then((res) => {
        console.log(res);
        history.push(`/battleCup/${id}/information`);
      })
      .catch((err) => toast.error(err.data && err.data.message));
  };

  return (
    <div className={s.registrationContainer}>
      <div className={s.contentContainer}>
        <div className={s.title}>Registration Form</div>

        <div className={s.teamInfo}>
          <div className={s.teamTitle}>{data?.name}</div>
          <div className={s.buttomContainerInfo}>
            <div className={s.name}>{data?.game.title}</div>
            <div className={s.vs}>
              {sizeTeam} vs {sizeTeam}
            </div>
          </div>
        </div>

        <div className={s.addContainer}>
          <div className={s.rosterContainer}>
            <div className={s.rosterTitle}>Choose Roster</div>
            <div className={s.rosterSubtitle}>
              Players required {sizeTeam}\{playersCount}
            </div>
          </div>
        </div>

        <div className={s.playerRow}>
          <div className={s.rowTitle}>Choose team</div>
        </div>
        {flag !== false ? (
          requestSuccess ? (
            <>
              {userTeams?.teams.map((el) => (
                <TeamContainer
                  key={el.id}
                  gameId={tournamentGameId}
                  id={el.id}
                  name={el.name}
                  role={el.role}
                  logo={el.logo}
                  btn={true}
                  setCountPlayers={setCountPlayers}
                  countPlayers={countPlayers}
                  setTeamRosterMembers={setTeamRosterMembers}
                  teamRosterMembers={teamRosterMembers}
                  setTeamId={setTeamId}
                  setTeamName={setTeamName}
                  userTeams={userTeams}
                  el={el}
                  setCurrentTeam={setCurrentTeam}
                  setFlag={setFlag}
                  setPlayersCount={setPlayersCount}
                  setRosterId={setRosterId}
                />
              ))}
            </>
          ) : (
            <CircularProgress />
          )
        ) : (
          currentTeam?.map((el) => (
            <TeamContainer
              key={el.id}
              id={el.id}
              name={el.name}
              role={el.role}
              logo={el.logo}
              btn={true}
              setCountPlayers={setCountPlayers}
              countPlayers={countPlayers}
              setTeamRosterMembers={setTeamRosterMembers}
              teamRosterMembers={teamRosterMembers}
              setTeamId={setTeamId}
              setTeamName={setTeamName}
              userTeams={userTeams}
              el={el}
              setCurrentTeam={setCurrentTeam}
              setFlag={setFlag}
              setPlayersCount={setPlayersCount}
              setRosterId={setRosterId}
            />
          ))
        )}
        {/* {membersRoster?.members?.map(el => <PlayersContainer
               key={el.id}
               id={el.id}
               name={el.name}
               role={el.role}
               title={el.title}
               logo={el.logo}
               btn={true}
               setCountPlayers={setCountPlayers}
               countPlayers={countPlayers}
               setTeamRosterMembers={setTeamRosterMembers}
               teamRosterMembers={teamRosterMembers}
            />)} */}

        {playersCount === sizeTeam ? (
          <button
            className={s.btnDone}
            style={{ textDecoration: "none" }}
            onClick={() => submitTeamRoster(id, rosterId)}
          >
            Registration
          </button>
        ) : (
          false
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    runGetUserData: () => dispatch(getUserData()),
  };
};
export default injectIntl(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(RegistrationTournament)
  )
);
