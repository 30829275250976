import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { API, API_ROUTER } from "../../api";
import { toast } from "react-toastify";

const CommunityItem = ({ id, name, description, follow }) => {
  const history = useHistory();

  // method
  function goToUrl(url) {
    history.push(url);
  }
  const [star, tongleStar] = useState(!follow);

  const tongStar = () => {
    API.request({
      ...API_ROUTER.follow.IsUserFollowCommunity,
      pathKeys: {
        communityId: id,
      },
    })
      .then((res) =>
        res.follow === true
          ? API.request({
              ...API_ROUTER.follow.stopFollowCommunity,
              pathKeys: {
                communityId: id,
              },
            })
              .then((res) => {
                toast.success(res.message);
              })
              .catch((err) => console.log(err))
          : API.request({
              ...API_ROUTER.follow.followCommunity,
              data: {
                communityId: id,
              },
            })
              .then((res) => {
                toast.success(res);
              })
              .catch((err) => console.log(err))
      )
      .catch((err) => console.log(err));
    if (star) {
      tongleStar(false);
    } else tongleStar(true);
  };
  return (
    // <div className="list__item">
    <div
      className="event-list__event tournament-list__event"
      style={{
        background: "transparent",
        backgroundColor: "#333333",
        // backgroundColor: "rgba(255, 255, 255, 0.1)",
        // TODO color + size border +1
        borderColor: "#1C1C1C",
        borderWidth: "3px",
      }}
      key={id}
    >
      <div
        className={
          star === false
            ? "event-list__star-image event-list__star-image--active"
            : "event-list__star-image"
        }
        onClick={() => tongStar()}
      ></div>

      <div
        className="event__game-image"
        onClick={() => goToUrl(`/community/${id}/events`)}
      ></div>
      <div
        className="event__name-stage-date"
        onClick={() => goToUrl(`/community/${id}/events`)}
      >
        <h4 className="event__name">{name ? name : "CUP AFRICANA"}</h4>
        <div className="event__decoration"></div>
        <p className="event__stage">
          {description.length > 30 ? description.slice(0, 30) : description}
        </p>
      </div>
    </div>
  );
};

export default CommunityItem;
