import React from "react";

const dots = () => (
    <svg width="91" height="149" viewBox="0 0 91 149" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.000121957 146.2C0.000121894 147.636 1.1642 148.8 2.60015 148.8C4.03611 148.8 5.2002 147.636 5.2002 146.2C5.2002 144.764 4.03611 143.6 2.60015 143.6C1.1642 143.6 0.000122019 144.764 0.000121957 146.2Z" fill="#D5CBFF"/>
            <path d="M0.000121957 122.3C0.000121894 123.736 1.1642 124.9 2.60015 124.9C4.03611 124.9 5.2002 123.736 5.2002 122.3C5.2002 120.864 4.03611 119.7 2.60015 119.7C1.1642 119.7 0.000122019 120.864 0.000121957 122.3Z" fill="#D5CBFF"/>
            <path d="M0.000121957 98.4003C0.000121894 99.8362 1.1642 101 2.60015 101C4.03611 101 5.2002 99.8362 5.2002 98.4003C5.2002 96.9643 4.03611 95.8003 2.60015 95.8003C1.1642 95.8003 0.000122019 96.9643 0.000121957 98.4003Z" fill="#D5CBFF"/>
            <path d="M0.000121957 74.4003C0.000121891 75.9003 1.20014 77.0002 2.60015 77.0002C4.10017 77.0002 5.2002 75.8003 5.2002 74.4003C5.2002 72.9003 4.00017 71.8003 2.60015 71.8003C1.20014 71.8003 0.000122018 73.0003 0.000121957 74.4003Z" fill="#D5CBFF"/>
            <path d="M0.000121957 50.4999C0.000121894 51.9359 1.1642 53.1 2.60015 53.1C4.03611 53.1 5.2002 51.9359 5.2002 50.4999C5.2002 49.064 4.03611 47.8999 2.60015 47.8999C1.1642 47.8999 0.000122019 49.064 0.000121957 50.4999Z" fill="#D5CBFF"/>
            <path d="M0.000121957 26.5999C0.000121894 28.0358 1.1642 29.1998 2.60015 29.1998C4.03611 29.1998 5.2002 28.0358 5.2002 26.5999C5.2002 25.164 4.03611 24 2.60015 24C1.1642 24 0.000122019 25.164 0.000121957 26.5999Z" fill="#D5CBFF"/>
            <path d="M0.000121957 2.59998C0.000121894 4.0359 1.1642 5.19995 2.60015 5.19995C4.03611 5.19995 5.2002 4.0359 5.2002 2.59998C5.2002 1.16405 4.03611 -5.08837e-08 2.60015 -1.13652e-07C1.1642 -1.76419e-07 0.000122019 1.16405 0.000121957 2.59998Z" fill="#D5CBFF"/>
            <path d="M21.4001 146.2C21.4001 147.636 22.5642 148.8 24.0001 148.8C25.436 148.8 26.6001 147.636 26.6001 146.2C26.6001 144.764 25.436 143.6 24.0001 143.6C22.5642 143.6 21.4001 144.764 21.4001 146.2Z" fill="#D5CBFF"/>
            <path d="M21.4001 122.3C21.4001 123.736 22.5642 124.9 24.0001 124.9C25.436 124.9 26.6001 123.736 26.6001 122.3C26.6001 120.864 25.436 119.7 24.0001 119.7C22.5642 119.7 21.4001 120.864 21.4001 122.3Z" fill="#D5CBFF"/>
            <path d="M21.4001 98.3998C21.4001 99.8357 22.5642 101 24.0001 101C25.436 101 26.6001 99.8357 26.6001 98.3998C26.6001 96.9639 25.436 95.7998 24.0001 95.7998C22.5642 95.7998 21.4001 96.9639 21.4001 98.3998Z" fill="#D5CBFF"/>
            <path d="M21.4001 74.3998C21.4001 75.8998 22.6001 76.9998 24.0001 76.9998C25.5001 76.9998 26.6001 75.7998 26.6001 74.3998C26.6001 72.8998 25.4001 71.7998 24.0001 71.7998C22.6001 71.7998 21.4001 72.9998 21.4001 74.3998Z" fill="#D5CBFF"/>
            <path d="M21.4001 50.4999C21.4001 51.9359 22.5642 53.1 24.0001 53.1C25.436 53.1 26.6001 51.9359 26.6001 50.4999C26.6001 49.064 25.436 47.8999 24.0001 47.8999C22.5642 47.8999 21.4001 49.064 21.4001 50.4999Z" fill="#D5CBFF"/>
            <path d="M21.4001 26.5999C21.4001 28.0358 22.5642 29.1998 24.0001 29.1998C25.436 29.1998 26.6001 28.0358 26.6001 26.5999C26.6001 25.164 25.436 24 24.0001 24C22.5642 24 21.4001 25.164 21.4001 26.5999Z" fill="#D5CBFF"/>
            <path d="M21.4001 2.59998C21.4001 4.0359 22.5642 5.19995 24.0001 5.19995C25.436 5.19995 26.6001 4.0359 26.6001 2.59998C26.6001 1.16405 25.436 -5.08822e-08 24.0001 -1.13649e-07C22.5642 -1.76415e-07 21.4001 1.16405 21.4001 2.59998Z" fill="#D5CBFF"/>
            <path d="M42.8003 146.2C42.8003 147.636 43.9643 148.8 45.4003 148.8C46.8362 148.8 48.0002 147.636 48.0002 146.2C48.0002 144.764 46.8362 143.6 45.4003 143.6C43.9643 143.6 42.8003 144.764 42.8003 146.2Z" fill="#D5CBFF"/>
            <path d="M42.8 122.3C42.8 123.736 43.9641 124.9 45.4 124.9C46.836 124.9 48 123.736 48 122.3C48 120.864 46.836 119.7 45.4 119.7C43.9641 119.7 42.8 120.864 42.8 122.3Z" fill="#D5CBFF"/>
            <path d="M42.8 98.3998C42.8 99.8357 43.9641 101 45.4 101C46.836 101 48 99.8357 48 98.3998C48 96.9639 46.836 95.7998 45.4 95.7998C43.9641 95.7998 42.8 96.9639 42.8 98.3998Z" fill="#D5CBFF"/>
            <path d="M42.8003 74.3998C42.8003 75.8998 44.0003 76.9998 45.4003 76.9998C46.9003 76.9998 48.0002 75.7998 48.0002 74.3998C48.0002 72.8998 46.8003 71.7998 45.4003 71.7998C44.0003 71.7998 42.8003 72.9998 42.8003 74.3998Z" fill="#D5CBFF"/>
            <path d="M42.8003 50.4999C42.8003 51.9359 43.9643 53.1 45.4003 53.1C46.8362 53.1 48.0002 51.9359 48.0002 50.4999C48.0002 49.064 46.8362 47.8999 45.4003 47.8999C43.9643 47.8999 42.8003 49.064 42.8003 50.4999Z" fill="#D5CBFF"/>
            <path d="M42.8 26.5999C42.8 28.0358 43.9641 29.1998 45.4 29.1998C46.836 29.1998 48 28.0358 48 26.5999C48 25.164 46.836 24 45.4 24C43.9641 24 42.8 25.164 42.8 26.5999Z" fill="#D5CBFF"/>
            <path d="M42.8003 2.59998C42.8003 4.0359 43.9643 5.19995 45.4003 5.19995C46.8362 5.19995 48.0002 4.0359 48.0002 2.59998C48.0002 1.16405 46.8362 -5.08822e-08 45.4003 -1.13649e-07C43.9643 -1.76415e-07 42.8003 1.16405 42.8003 2.59998Z" fill="#D5CBFF"/>
            <path d="M64.3 146.2C64.3 147.636 65.4641 148.8 66.9 148.8C68.336 148.8 69.5 147.636 69.5 146.2C69.5 144.764 68.336 143.6 66.9 143.6C65.4641 143.6 64.3 144.764 64.3 146.2Z" fill="#D5CBFF"/>
            <path d="M64.3003 122.3C64.3003 123.736 65.4643 124.9 66.9003 124.9C68.3362 124.9 69.5002 123.736 69.5002 122.3C69.5002 120.864 68.3362 119.7 66.9003 119.7C65.4643 119.7 64.3003 120.864 64.3003 122.3Z" fill="#D5CBFF"/>
            <path d="M64.3 98.3998C64.3 99.8357 65.4641 101 66.9 101C68.336 101 69.5 99.8357 69.5 98.3998C69.5 96.9639 68.336 95.7998 66.9 95.7998C65.4641 95.7998 64.3 96.9639 64.3 98.3998Z" fill="#D5CBFF"/>
            <path d="M64.2002 74.3998C64.2002 75.8998 65.4002 76.9998 66.8002 76.9998C68.3002 76.9998 69.4001 75.7998 69.4001 74.3998C69.4001 72.8998 68.2002 71.7998 66.8002 71.7998C65.4002 71.7998 64.2002 72.9998 64.2002 74.3998Z" fill="#D5CBFF"/>
            <path d="M64.3 50.4999C64.3 51.9359 65.4641 53.1 66.9 53.1C68.336 53.1 69.5 51.9359 69.5 50.4999C69.5 49.064 68.336 47.8999 66.9 47.8999C65.4641 47.8999 64.3 49.064 64.3 50.4999Z" fill="#D5CBFF"/>
            <path d="M64.3003 26.5999C64.3003 28.0358 65.4643 29.1998 66.9003 29.1998C68.3362 29.1998 69.5002 28.0358 69.5002 26.5999C69.5002 25.164 68.3362 24 66.9003 24C65.4643 24 64.3003 25.164 64.3003 26.5999Z" fill="#D5CBFF"/>
            <path d="M64.3003 2.59998C64.3003 4.0359 65.4643 5.19995 66.9003 5.19995C68.3362 5.19995 69.5002 4.0359 69.5002 2.59998C69.5002 1.16405 68.3362 -5.08823e-08 66.9003 -1.13649e-07C65.4643 -1.76415e-07 64.3003 1.16405 64.3003 2.59998Z" fill="#D5CBFF"/>
            <path d="M85.7002 146.2C85.7002 147.636 86.8642 148.8 88.3002 148.8C89.7361 148.8 90.9001 147.636 90.9001 146.2C90.9001 144.764 89.7361 143.6 88.3002 143.6C86.8642 143.6 85.7002 144.764 85.7002 146.2Z" fill="#D5CBFF"/>
            <path d="M85.7002 122.3C85.7002 123.736 86.8642 124.9 88.3002 124.9C89.7361 124.9 90.9001 123.736 90.9001 122.3C90.9001 120.864 89.7361 119.7 88.3002 119.7C86.8642 119.7 85.7002 120.864 85.7002 122.3Z" fill="#D5CBFF"/>
            <path d="M85.7002 98.3998C85.7002 99.8357 86.8642 101 88.3002 101C89.7361 101 90.9001 99.8357 90.9001 98.3998C90.9001 96.9639 89.7361 95.7998 88.3002 95.7998C86.8642 95.7998 85.7002 96.9639 85.7002 98.3998Z" fill="#D5CBFF"/>
            <path d="M85.6 74.3998C85.6 75.8998 86.8 76.9998 88.2 76.9998C89.7 76.9998 90.8 75.7998 90.8 74.3998C90.8 72.8998 89.6 71.7998 88.2 71.7998C86.8 71.7998 85.6 72.9998 85.6 74.3998Z" fill="#D5CBFF"/>
            <path d="M85.7002 50.4999C85.7002 51.9359 86.8642 53.1 88.3002 53.1C89.7361 53.1 90.9001 51.9359 90.9001 50.4999C90.9001 49.064 89.7361 47.8999 88.3002 47.8999C86.8642 47.8999 85.7002 49.064 85.7002 50.4999Z" fill="#D5CBFF"/>
            <path d="M85.7002 26.5999C85.7002 28.0358 86.8642 29.1998 88.3002 29.1998C89.7361 29.1998 90.9001 28.0358 90.9001 26.5999C90.9001 25.164 89.7361 24 88.3002 24C86.8642 24 85.7002 25.164 85.7002 26.5999Z" fill="#D5CBFF"/>
            <path d="M85.7002 2.59998C85.7002 4.0359 86.8642 5.19995 88.3002 5.19995C89.7361 5.19995 90.9001 4.0359 90.9001 2.59998C90.9001 1.16405 89.7361 -5.08823e-08 88.3002 -1.13649e-07C86.8642 -1.76415e-07 85.7002 1.16405 85.7002 2.59998Z" fill="#D5CBFF"/>
    </svg>
)

export default dots