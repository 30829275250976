import React, { useState, useContext, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./Discipline.css";
import { useDispatch, useSelector } from "react-redux";
import loadUserGames from "../../../helpers/games/loadUserGames";
import { setCreateRosterValues } from "../../../redux/actions";
import image from "../../../assets/no-image.png";
import { useHistory } from "react-router-dom";
import { API, API_ROUTER } from "../../../api";
import { toast } from "react-toastify";
import { LinearProgress } from "@material-ui/core";

const Discipline = (props) => {
  let id = useParams();
  const dispatch = useDispatch();
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [game, changeGameDiscipline] = useState(false);
  const [games, setGames] = useState([]);
  const [nickInput, setNickInput] = useState("");

  const history = useHistory();
  const changeGame = () => {
    // API.request({
    //   ...API_ROUTER.teams.postTeamGame,
    //   pathKeys: {
    //     teamId: id.teamId,
    //   },
    //   data: {
    //     gameId: game.id,
    //   },
    // })
    //   .then(() => {
    //     history.push(`/teams/team/${id.teamId}/roster/create`);
    //   })
    //   .catch((err) => console.log(err));
    dispatch(setCreateRosterValues({ discipline: game }));

    history.push(`/teams/team/${id.teamId}/roster/create`);
  };
  console.log(game);
  useEffect(() => {
    loadUserGames()
      .then((res) => {
        setGames(() => res.games);
        setRequestSuccess(true);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  function showToast() {
    toast.success("Add the game to your profile to create a roster");
  }
  return requestSuccess ? (
    <div>
      <div className="discipline">
        <div className="games__title-icon">
          <h1 className="games__title">discipline</h1>
          <i
            className="icon icon-exclamation-mark"
            onClick={() => showToast()}
          ></i>
        </div>
        <ul className="discipline__list">
          {games?.map((el) => (
            <li
              className={
                el.game?.title === game.game?.title
                  ? "games__item-list  games__item-list--active"
                  : "games__item-list"
              }
              onClick={(e) => changeGameDiscipline(el)}
              key={el.id}
            >
              <img
                className="games__item-list-image"
                src={el?.game?.image ? el?.game?.image : image}
                alt={el.game?.title}
                width="100"
                height="100"
              />
              {el.game?.title.length > 16 ? (
                <p className="games__item-list-text">
                  {el.game?.title.slice(0, 16) + "..."}
                </p>
              ) : (
                <p className="games__item-list-text">{el.game?.title}</p>
              )}
            </li>
          ))}
        </ul>
        <div className="submit-button-wrapper">
          {game ? (
            <button className="submit-button" onClick={() => changeGame()}>
              Select
            </button>
          ) : (
            <div className="submit-button submit-button--disabled">Select</div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <LinearProgress />
  );
};

export default withRouter(Discipline);
