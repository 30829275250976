import React, { useEffect, useState } from "react";

import { API, API_ROUTER } from "../../api";
import styled from "styled-components";
import { useParams, withRouter, useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { v4 as uuidv4 } from "uuid";
//

import Button from "../UI/buttons/buttons-login_register/button/Button";
import CupTab from "../cup-tab";
import bg_pubg from "../../assets/images/pubg_banner.png";
import azerbaijan from "../../assets/images/azerbaijan.png";

import { convertDate } from "../../helpers/dates-formatter";

const navList = (id) => [
  { id: uuidv4(), title: "INFORMATION", to: `/battleCup/${id}/information` },
  { id: uuidv4(), title: "RULES", to: `/battleCup/${id}/rules` },
  { id: uuidv4(), title: "PARTICIPANTS", to: `/battleCup/${id}/participants` },
  { id: uuidv4(), title: "MATCHES", to: `/battleCup/${id}/matches` },
  {
    id: uuidv4(),
    title: "BRACKET",
    // to1: `/battleCup/${id}/bracket/preliminary_tournament`,
    to: `/battleCup/${id}/bracket/group_stage`,
    to1: `/battleCup/${id}/bracket/play_off`,
  },
  // { id: uuidv4(), title: "STATISTICS", to: `/battleCup/${id}/statistics` },
  { id: uuidv4(), title: "RESULTS", to: `/battleCup/${id}/statistics` },
];

export function Top(props) {
  let { tournament } = props;
  const [answerFromServer, setAnswerFromServer] = useState("");
  const [userData, setUserData] = useState(null);
  const [isUserDataParicipant, setIsUserDataParicipant] = useState();

  useEffect(() => {
    API.request({
      ...API_ROUTER.user.getMyData,
    })
      .then((res) => {
        setUserData(res);
      })
      .catch((err) => console.log(err));
  }, []);

  const submitTeamRoster = (tournamentId, userInfo) => {
    API.request({
      ...API_ROUTER.tournaments.postTeamRoster,
      pathKeys: {
        tournamentId: tournamentId,
      },
      data: {
        user: {
          id: userInfo.id,
          nickname: userInfo.nickname,
        },
        team: null,
      },
    })
      .then((res) => {
        setAnswerFromServer("successfully");
      })
      .catch((err) => setAnswerFromServer(err.message));
  };
  useEffect(() => {
    if (userData?.id) {
      API.request({
        ...API_ROUTER.tournaments.getUserCurrentTournament,
        pathKeys: {
          userId: userData?.id,
          tournamentId: tournament?.id,
        },
      })
        .then((res) => {
          setIsUserDataParicipant(res);
        })
        .catch((err) => console.log(err));
    }
  }, [userData?.id, tournament?.id]);
  let { id } = useParams();
  const checkParticipationTypeData = (type, fn) => {
    type === "user"
      ? submitTeamRoster(id, userData)
      : fn(`/battleCup/${tournament?.id}/registrationTournament`);
  };
  const history = useHistory();
  const goToUrl = (url) => {
    history.push(url);
  };
  return (
    <StyledTop>
      <StyledTopBanner
        bg={
          tournament?.media?.pageHeaderBanner
            ? tournament?.media?.pageHeaderBanner
            : false
        }
      >
        <div className="body">
          <div />
          {/*<h1>{tournament?.name || "Tournament"}</h1>*/}
          {tournament?.media?.logo ? (
            <img
              width={40}
              src={tournament?.media?.logo}
              alt="azerbaijan"
              className="c-p"
            />
          ) : (
            false
          )}
        </div>
      </StyledTopBanner>
      <StyledNews>
        <p>
          <h2>{tournament?.name ? tournament?.name : false}</h2> <br />{" "}
          <h3>{tournament?.layer ? tournament?.layer : false}</h3>
          {tournament?.startedAt && tournament?.endedAt
            ? convertDate(tournament?.startedAt) +
              " - " +
              convertDate(tournament?.endedAt)
            : false}{" "}
          {tournament?.tournamentType ? tournament?.tournamentType : false}
          {tournament?.location ? "|" + tournament?.location : false}
        </p>

        <Button active type="cuptop" status={tournament?.status}>
          {/*<FormattedMessage id="battlecup.component.top.buttonRegister" />*/}

          <p style={{ fontSize: "12px" }}>
            {tournament?.status || "Not actual"}
          </p>
        </Button>
      </StyledNews>{" "}
      <StyledButton>
        {" "}
        <article className="events__buttons-and-static">
          {/* <div className="events__button">
            <h1 className="events__button-text">Community</h1>
          </div> */}

          <div className="events__button">
            <h1 className="events__button-text">Rating</h1>
            <i
              className="icon icon-password"
              style={{ marginLeft: 5, width: 15, height: 15 }}
            />
          </div>

          {/* <div className="events__button">
            <h1 className="events__button-text">Facebook</h1>
          </div>{" "} */}
          {isUserDataParicipant?.team ||
          isUserDataParicipant?.teamRoster ||
          isUserDataParicipant?.user ? (
            <section className="btn__wrapper">
              <div>
                <Button type="cupbottom">You already registered</Button>
              </div>
            </section>
          ) : tournament?.status === "registration" ? (
            <section className="btn__wrapper">
              {answerFromServer === "successfully" ? (
                <div>You are registration successfully</div>
              ) : (
                <div
                  onClick={() =>
                    checkParticipationTypeData(
                      props.participationTypeData,
                      goToUrl
                    )
                  }
                  /*  style={{ textDecoration: "none" }} */
                  /* to={props.participationTypeData === 'user' ? `/battleCup/${tournament?.id}/registrationTournament` : false} */
                >
                  <Button active type="cupbottom">
                    <FormattedMessage id="battlecup.information.registrationButton" />
                  </Button>
                </div>
              )}
            </section>
          ) : (
            false
          )}
        </article>
      </StyledButton>
      <CupTab nav__list={navList(id)} />
    </StyledTop>
  );
}

export default withRouter(Top);

const StyledTop = styled.div``;

const StyledTopBanner = styled.div`
  background: ${({ bg }) => `url(${bg}) no-repeat center `};
  background-color: var(--footer-back);
  background-size: cover;
  min-height: 300px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;

  & > .left__logo {
    img {
      position: absolute;
      top: 18px;
      left: 18px;
    }
  }

  & > .body {
    width: 100%;
    padding: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    & > h1 {
      text-transform: uppercase;
      font-size: 26px;
      font-weight: bold;
      font-style: normal;
      letter-spacing: -1.01px;
      line-height: normal;
    }
  }
`;
const StyledButton = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 16px;

  .events__buttons-and-static {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
  .events__button {
    background: #333333;
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 10px;
    margin: 10px;
    border-radius: 15px;
    cursor: pointer;
    width: 100px;
    align-items: center;
    border: 1px solid white;
  }
  .events__button-text {
    color: white;
    font-size: 14px;
    padding-right: 10px;
  }
`;
const StyledNews = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 16px;

  & > p {
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: -0.59px;
    line-height: normal;
  }
  .events__buttons-and-static {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
  .events__button {
    background: #333333;
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 10px;
    margin: 10px;
    border-radius: 15px;
    cursor: pointer;
    width: 100px;
    align-items: center;
  }
  .events__button-text {
    color: black;
    font-size: 14px;
    padding-right: 10px;
  }
`;
