import React, { useState, useEffect } from "react";
import PlayersContainer from "../../../components/players-container";

import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import SubmitButton from "../components/SubmitButton/SubmitButton";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import NoImage from "../../../assets/no-image.png";
import { withRouter } from "react-router-dom";
import {
  isFieldEmpty,
  isSelectEmpty,
  isFieldEmptyAndLength,
  setValueToSelect,
} from "../../../helpers";
import { toast } from "react-toastify";
import { data as dataCountry, languageList, initialErrors } from "./const";
import { API, API_ROUTER } from "../../../api";

import { LinearProgress } from "@material-ui/core";
import SelectRoster from "../../../components/UI/forms/select-roster";

const EditRoster = (props) => {
  const { countriesList } = props;
  const { userData } = props;

  let id = useParams();
  const dispatch = useDispatch();

  const { discipline } = useSelector((state) => state.roster);
  const [errors, setErrors] = useState(initialErrors);
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [teamMembers, setTeamMembers] = useState();
  const [rosterName, setRosterName] = useState();
  const [rosterCountry, setRosterCountry] = useState([]);
  const [getDiscipline, setDiscipline] = useState();
  const [getDisciplineLogo, setDisciplineLogo] = useState();
  const [newRoster, setNewRoster] = useState([]);
  const [countPlayers, setCountPlayers] = useState(0);
  const [teamRosterMembers, setTeamRosterMembers] = useState([]);
  const [languagesList, setLanguagesList] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  console.log(teamRosterMembers);
  console.log(newRoster);
  useEffect(() => {
    if (countriesList === undefined) {
      return;
    }
    API.request({
      ...API_ROUTER.teams.getMembersGame,
      pathKeys: {
        teamId: id.teamId,
        gameId: getDiscipline?.id,
      },
    })
      .then((res) => {
        setNewRoster(res.members);
        setRequestSuccess(true);
      })
      .catch((err) => console.log(err));
  }, [getDiscipline]);

  useEffect(() => {
    API.request({
      ...API_ROUTER.roster.getRoster,
      pathKeys: {
        rosterId: id.id,
      },
    })

      .then((res) => {
        console.log(res);
        setRosterName(res.rosterName);
        setTeamMembers(res.rosterMembers.members);
        setTeamRosterMembers(res.rosterMembers.members);
        setDiscipline(res.gameId);
        setDisciplineLogo(res.gameLogo);
        let state = { values: {} };
        const newLanguageArr = res.languages.join();

        const format = () => {
          console.log(newLanguageArr);
          state.values.languages = newLanguageArr;
          state.values.country = res.country;
        };
        format();

        setSelectedLanguage(
          (state.values.languages = res.languages.map((language) =>
            setValueToSelect(language)
          ))
        );
        setRosterCountry(
          (state.values.country = setValueToSelect(res.country))
        );
        API.request({
          ...API_ROUTER.teams.getMembersGame,
          pathKeys: {
            teamId: id.teamId,
            gameId: res.gameId,
          },
        })
          .then((res) => {
            setNewRoster(res.members);
            setRequestSuccess(true);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, []);
  const language = selectedLanguage.flat();
  const [selectedLanguageNew, setSelectedLanguageNew] = useState(language);
  useEffect(() => {
    if (countriesList === undefined) {
      return;
    }
    API.request({
      ...API_ROUTER.public.getLanguages,
    })
      .then((res) => {
        const keys = Object.keys(res);
        const values = Object.values(res);
        const list = [];
        keys.forEach((key, index) => {
          list.push({
            value: key,
            label: values[index],
          });
        });
        setLanguagesList(list);
        setRequestSuccess(true);
      })
      .catch((err) => console.log(err));
  }, [countriesList]);
  const newDataMember = JSON.parse(
    JSON.stringify(teamRosterMembers),
    function (key, value) {
      if ((key === "role") & (value === "owner")) {
        return "capitan";
      } else if ((key === "role") & (value === "member")) {
        return "player";
      } else {
        return value;
      }
    }
  );
  // const newRosterArr = newRoster.filter(
  //   (el) => el.userId !== editId
  // );
  // const newRosterArr = newRoster.filter(
  //   (el) => !teamMembers.includes(el.userId == el.id)
  // );
  // console.log(newRosterArr);
  const newRosterArr = newRoster.filter(
    (item) => !teamMembers.some((teamMembers) => teamMembers.userId === item.id)
  );
  const newSelectedLanguages = [];
  const formatedLanguage = () => {
    selectedLanguageNew.map((el) => {
      newSelectedLanguages.push(el.value);
      return newSelectedLanguages;
    });
  };
  console.log(formatedLanguage());
  console.log({
    name: rosterName,
    gameId: getDiscipline,
    country: rosterCountry[0]?.value,
    languages: newSelectedLanguages,
    members: newDataMember,
  });
  const closeChoise = (e) => {
    e.preventDefault();
    const newErrors = initialErrors;
    setErrors({ ...errors, ...newErrors });
    newErrors.name = isFieldEmpty(rosterName);
    newErrors.gameId = isFieldEmpty(getDiscipline);
    newErrors.languages = isSelectEmpty(newSelectedLanguages);
    newErrors.country = isFieldEmpty(rosterCountry[0]?.value);
    newErrors.members = isSelectEmpty(newDataMember);

    if (Object.values(newErrors).some((value) => value.length)) {
      if (newErrors.name === "This field is required") {
        toast.error("please enter roster name");
      }
      if (newErrors.country === "This field is required") {
        toast.error("please enter roster country");
      }
      if (newErrors.languages === "This field is required") {
        toast.error("please enter roster languages");
      }
      if (newErrors.gameId === "This field is required") {
        toast.error("please enter roster discipline");
      }
      if (newErrors.members === "This field is required") {
        toast.error("please enter roster members");
      }
    }

    if (!Object.values(newErrors).some((value) => value.length)) {
      const data = {
        name: rosterName,
        gameId: getDiscipline,
        country: rosterCountry[0]?.value,
        languages: newSelectedLanguages,
        members: newDataMember,
      };

      API.request({
        ...API_ROUTER.roster.editRoster,
        pathKeys: {
          teamId: id.teamId,
          teamRosterId: id.id,
        },
        data,
      })

        .then(() => history.push(`/teams/team/${id.teamId}`))
        .catch((err) => toast.error(err.data && err.data.message));
    }
  };
  const history = useHistory();
  function showToast() {
    toast.success(
      "Roster includes players who have added discipline to their profile"
    );
  }
  const renderButton = () => {
    return (
      <SubmitButton
        type="submit"
        buttonText={"Edit roster"}
        disabled={true}
        clickFunction={closeChoise}
      />
    );
  };

  return requestSuccess ? (
    <div>
      <div className="create-roster">
        <h1 className="create-roster__title">Edit Roster</h1>
        <form className="create-roster__form" action="">
          <section className="create-roster__form-discipline">
            <div className="form-discipline">
              <div className="change-roster__title-and-link">
                <p className="title-and-link__title">Roster</p>
              </div>
              {/* <p className="form-discipline__title">Discipline</p> */}
              <i
                className="icon icon-exclamation-mark"
                onClick={() => showToast()}
              ></i>
            </div>
            {/* {discipline ? ( */}
            <ul className="games__list">
              <li className={"games__item-list"}>
                <img
                  className="games__item-list-image"
                  src={getDisciplineLogo ? getDisciplineLogo : NoImage}
                  alt={getDisciplineLogo}
                  width="100"
                  height="100"
                />
              </li>
            </ul>
          </section>
          <section className="create-roster__roster-name">
            <h3 className="roster-name__title">Roster Name*</h3>
            <input
              className="roster-name__input"
              type="text"
              placeholder="Enter Roster Name"
              value={rosterName}
              onChange={(e) => setRosterName(e.target.value)}
            />
          </section>

          <section className="create-roster__language">
            <h3 className="language__title">Select The Speaking Language</h3>

            <article className="create-roster__language">
              <SelectRoster
                placeholder="Select Country..."
                options={languagesList}
                multiple
                values={language}
                onChange={(e) => setSelectedLanguageNew(e)}
              />
            </article>
          </section>

          <section className="create-roster__language">
            <h3 className="language__title">Select Country</h3>

            <article className="create-roster__language">
              <>
                <SelectRoster
                  required
                  placeholder="Select Country..."
                  options={countriesList}
                  values={rosterCountry}
                  onChange={(e) => setRosterCountry(e, "country")}
                />
              </>
            </article>
          </section>
          <section className="create-roster__change-roster">
            <>
              <div className="change-roster__title-and-link">
                <p className="title-and-link__title">Current Roster</p>
                <i
                  className="icon icon-exclamation-mark"
                  onClick={() => showToast()}
                ></i>
              </div>
              <div className="contentContainer">
                {teamMembers?.map((member) => (
                  <>
                    <PlayersContainer
                      key={member.id}
                      id={member.id}
                      editId={member.userId}
                      name={member.nickname}
                      role={member.role}
                      title={member.title}
                      logo={member.avatar}
                      btn={true}
                      setCountPlayers={setCountPlayers}
                      countPlayers={countPlayers}
                      setTeamRosterMembers={setTeamRosterMembers}
                      teamRosterMembers={teamRosterMembers}
                      teamMembers={teamMembers}
                      setTeamMembers={setTeamMembers}
                      edit={true}
                    />
                  </>
                ))}
              </div>
            </>
          </section>
          <section className="create-roster__change-roster">
            <>
              <div className="change-roster__title-and-link">
                <p className="title-and-link__title">Add new Roster</p>
              </div>
              <div className="contentContainer">
                {newRosterArr?.map((member) => (
                  <>
                    <PlayersContainer
                      key={member.id}
                      id={member.id}
                      editId={member.userId}
                      name={member.nickname}
                      role={member.role}
                      title={member.title}
                      logo={member.avatar}
                      btn={true}
                      setCountPlayers={setCountPlayers}
                      countPlayers={countPlayers}
                      setTeamRosterMembers={setTeamRosterMembers}
                      teamRosterMembers={teamRosterMembers}
                      teamMembers={teamMembers}
                      setTeamMembers={setTeamMembers}
                      main={true}
                    />
                  </>
                ))}
              </div>
            </>
          </section>
          {renderButton()}
        </form>
      </div>
    </div>
  ) : (
    <>
      <LinearProgress />
      <div className="title-and-link__edit-roster-link title-and-link__edit-roster-link--disabled">
        You have to choose
        <button
          className="title-and-link__edit-roster-link"
          onClick={() =>
            history.push(`/teams/team/${id.teamId}/roster/discipline`)
          }
        >
          Discipline
        </button>
        before making a roster
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    countriesList: state.countriesList,

    userData: state.userData,
  };
};

export default withRouter(connect(mapStateToProps)(EditRoster));
