import React, { useState, useEffect } from "react";
import "./LeaderBoard.css";
import vp from "../../assets/images/vp.png";
import teamNew from "../../assets/images/new.png";
import liquid from "../../assets/images/team-liquid.png";
import flag from "../../assets/images/flag-1.png";
import { API, API_ROUTER } from "../../api";
import NoImage from "../../assets/no-image.png";
import { FormattedMessage } from "react-intl";
import MatchSearchItem from "../calendar/MatchSearchItem";

const league = [
  {
    id: "2022-01",
    title: "SAGES Legendary Series",
    month: "SAGES Legendary Series",
  },
  {
    id: "2022-07",
    title: "International",
    month: "International",
  },
  {
    id: "2022-08",
    title: "Continental",
    month: "Continental",
  },
  {
    id: "2022-09",
    title: "Regional",
    month: "Regional",
  },
  {
    id: "2022-10",
    title: "National",
    month: "National",
  },
  {
    id: "2022-11",
    title: "Local",
    month: "Local",
  },
];
const games = [
  {
    id: "01G2CHY9GWJGXBJ4XAS449XSZC",
    title: "CS:GO",
    month: "CS:GO",
  },
  {
    id: "01G2CHY9GWJGXBJ4XAS449XSZE",
    title: "Dota2",
    month: "Dota2",
  },
  {
    id: "01G2CHY9GWJGXBJ4XAS449XSZ9",
    title: "COD Mobile",
    month: "COD Mobile",
  },
  {
    id: "01G2CHY9GWJGXBJ4XAS449XSZG",
    title: "FIFA 22",
    month: "FIFA 22",
  },
  {
    id: "01G2CHY9GWJGXBJ4XAS449XSZ8",
    title: "Clash Royale",
    month: "Clash Royale",
  },
  {
    id: "01G74KPXVQYX0RZG5S9E9TTCMX",
    title: "Clash of Clans",
    month: "Clash of Clans",
  },
  {
    id: "01G2CHY9GXAQN51MP3BXW8HE69",
    title: "PUBG Mobile",
    month: "PUBG Mobile",
  },
  {
    id: "01G74KQYS2GYZKRR9DX6VX9TFB",
    title: "Critical Ops",
    month: "Critical Ops",
  },
  {
    id: "01G2CHY9GXAQN51MP3BXW8HE6F",
    title: "Tekken7",
    month: "Tekken7",
  },
  {
    id: "01G2CHY9GXAQN51MP3BXW8HE6D",
    title: "Rocket League",
    month: "Rocket League",
  },
  {
    id: "01G2CHY9GXAQN51MP3BXW8HE6G",
    title: "Valorant",
    month: "Valorant",
  },
];
const LeaderBoard = () => {
  const [participants, setParticipants] = useState();
  const [filter, setFilter] = useState("tekken");
  const [modalMonthWindow, openMonth] = useState(null);
  const [modalGameWindow, openGame] = useState(null);
  const [monthName, setMonthName] = useState("select layer");
  const [month, setMonth] = useState();
  const [loadAllGames, setLoadAllgames] = useState([]);
  const [chosenGame, setChosenGame] = useState();
  const [chosenGameName, setChosenGameName] = useState("select game");
  const [gameId, setGameId] = useState();

  console.log(chosenGameName, monthName, gameId);
  useEffect(() => {
    getData(setLoadAllgames, "games", API_ROUTER.games.getGames);
  }, []);
  function getData(dataSetter, type, api) {
    return API.request({ ...api })
      .then((res) => {
        dataSetter(res[type]);
      })
      .catch((err) => {
        // toast.error(err?.data && err?.data?.message || `Load of ${type} failed`);
        console.log(err);
      });
  }
  console.log(window.matchMedia("(min-width: 500px)").matches);
  const changeMonth = (name) => {
    setMonth(name);
    openMonth(null);
  };

  const changeMonthName = (name) => {
    setMonthName(name);
    openMonth(null);
  };
  const tongleMonth = () => {
    if (modalMonthWindow) {
      openMonth(null);
    } else {
      openMonth("Open");
    }
  };
  const changeGame = (name) => {
    setChosenGame(name);
    openGame(null);
  };
  const changeGameName = (name) => {
    setChosenGameName(name);
    openGame(null);
  };
  const gameIdChange = (id) => {
    setGameId(id);
  };
  const tongleGame = () => {
    if (modalGameWindow) {
      openGame(null);
    } else {
      openGame("Open");
    }
  };

  useEffect(() => {
    API.request({
      ...API_ROUTER.rating.getRatingTable,
      data: {
        layer: monthName === "select layer" ? null : monthName,
        gameId: gameId === "select game" ? null : gameId,
      },
    })
      .then((res) => {
        setParticipants(res.rows);
      })
      .catch((err) => console.log(err));
  }, [monthName, gameId]);

  // let sortArr = dataPlug.sort((a, b) => b.pts - a.pts);
  return (
    <div>
      <section className="leaderbord">
        <>
          <div className="match-search__search-tournaments-form">
            <MatchSearchItem
              title="Layer"
              id="match-search__month"
              value={monthName}
              name="match-search__name-month"
              onClickFunction={() => tongleMonth()}
              modalWindow={modalMonthWindow}
              ConstDataArr={league}
              changeFunction={changeMonth}
              changeName={changeMonthName}
            />

            <MatchSearchItem
              title={<FormattedMessage id="calendar.filter.game" />}
              id="match-search__game"
              value={chosenGameName}
              name="match-search__name-game"
              onClickFunction={() => tongleGame()}
              modalWindow={modalGameWindow}
              ConstDataArr={games}
              changeFunction={changeGame}
              changeName={changeGameName}
              gameId={gameIdChange}
            />
            {/* <div className="search-tournaments-form__submit-wrapper">
              <button
                className="search-tournaments-form__submit"
                // onClick={() => filter()}
              >
                Apply filters
              </button>
              <button
                className="search-tournaments-form__submit"
                style={{ color: "#d74527" }}
                // onClick={() => clearFilter()}
              >
                Clear filters
              </button>
            </div> */}
          </div>
        </>
        <form action="" className="leaderbord__form">
          {/* <div className="leaderbord__form-competition-rank">
            <input
              className="form-competition-rank__input"
              type="radio"
              name="competition-rank"
              value="regional"
              id="competition-rank__regional"
            />
            <label
              className="form-competition-rank__label"
              htmlFor="competition-rank__regional"
            >
              regional
            </label>
            <input
              className="form-competition-rank__input"
              type="radio"
              name="competition-rank"
              value="national"
              id="competition-rank__national"
            />
            <label
              className="form-competition-rank__label"
              htmlFor="competition-rank__national"
            >
              national
            </label>
            <input
              className="form-competition-rank__input"
              type="radio"
              name="competition-rank"
              value="continental"
              id="competition-rank__continental"
            />
            <label
              className="form-competition-rank__label"
              htmlFor="competition-rank__continental"
            >
              continental
            </label>
          </div> */}
          {/* <li className="leaderbord__form-game">
              <input
                className="form-game__input"
                type="radio"
                id="form-game__pubg"
                name="form-game"
                value="pubg"
              />
              <label className="form-game__label" htmlFor="form-game__pubg">
                PUBG
              </label>
            </li>
            <li className="leaderbord__form-game">
              <input
                className="form-game__input"
                type="radio"
                id="form-game__fifa"
                name="form-game"
                value="fifa"
              />
              <label className="form-game__label" htmlFor="form-game__fifa">
                FIFA
              </label>
            </li> */}
        </form>
        <ul className="leaderbord__result-list">
          <li className="leaderbord__result-list-item leaderbord__result-list-item--header">
            <p className="leaderbord__result-team">Team</p>
            <p className="leaderbord__result-pts">Pts</p>
            <p className="leaderbord__result-win">W</p>
            <p className="leaderbord__result-lose">L</p>
            <p className="leaderbord__result-lose">D</p>
            <p className="leaderbord__result-percent">%</p>
            <p className="leaderbord__result-flag">C</p>
            <p className="leaderbord__result-infos-link">infos</p>
          </li>
          {participants?.length > 0 ? (
            participants?.map((el) => (
              <li
                className={
                  participants?.indexOf(el) % 2 == 0
                    ? "leaderbord__result-list-item leaderbord__result-list-item--also-gray"
                    : "leaderbord__result-list-item leaderbord__result-list-item--gray"
                }
              >
                <p
                  className={
                    participants?.indexOf(el) == 0
                      ? "leaderbord__result-place id-first"
                      : participants?.indexOf(el) == 1
                      ? "leaderbord__result-place id-second"
                      : participants?.indexOf(el) == 2
                      ? "leaderbord__result-place id-third"
                      : "leaderbord__result-place"
                  }
                >
                  {participants?.indexOf(el) + 1}
                </p>
                <p className="leaderbord__result-emblem">
                  <img
                    src={el.userAvatar ? el.userAvatar : NoImage}
                    alt={el.logo ? el.logo : NoImage}
                    width="28"
                    height="28"
                    style={
                      el.status === "pro" ? { border: "2px solid gold" } : {}
                    }
                  />
                </p>
                <p className="leaderbord__result-tag">
                  {window.matchMedia("(min-width: 750px)").matches
                    ? el.userNickname.length > 12
                      ? el.userNickname.slice(0, 11) + "..."
                      : el.userNickname
                    : false}
                  {window.matchMedia("(max-width: 750px)").matches &
                  window.matchMedia("(min-width: 500px)").matches
                    ? el.userNickname.length > 10
                      ? el.userNickname.slice(0, 5) + "..."
                      : el.userNickname
                    : false}
                  {window.matchMedia("(max-width: 500px)").matches &
                  window.matchMedia("(min-width: 300px)").matches
                    ? el.userNickname.length > 7
                      ? el.userNickname.slice(0, 6) + "..."
                      : el.userNickname
                    : false}
                </p>
                <p className="leaderbord__result-pts">{el.value}</p>
                <p className="leaderbord__result-win">{el.win}</p>
                <p className="leaderbord__result-lose">{el.lose}</p>
                <p className="leaderbord__result-lose">{el.draw}</p>

                <p className="leaderbord__result-percent">
                  {isNaN((el.win / (el.win + el.lose)).toFixed(2))
                    ? 0
                    : (el.win / (el.win + el.lose)).toFixed(2)}
                </p>
                <p className="leaderbord__result-flag">
                  <img
                    src={el.flag ? el.flag : NoImage}
                    alt=""
                    width="29"
                    height="19"
                  />
                </p>
                <a
                  href={`/id/${el?.userId}`}
                  className="leaderbord__result-infos-link"
                >
                  Infos ...
                </a>
              </li>
            ))
          ) : (
            <li
              className={
                "leaderbord__result-list-item leaderbord__result-list-item--gray"
              }
            >
              <p className={"leaderbord__result-place"}></p>
              <p className="leaderbord__result-emblem">
                {/* <img
                  src={el.userAvatar ? el.userAvatar : NoImage}
                  alt={el.logo ? el.logo : NoImage}
                  width="28"
                  height="28"
                /> */}
              </p>
              <p className="leaderbord__result-tag">
                No info about users or teams
                {/* {window.matchMedia("(min-width: 750px)").matches
                  ? el.userNickname.length > 12
                    ? el.userNickname.slice(0, 11) + "..."
                    : el.userNickname
                  : false}
                {window.matchMedia("(max-width: 750px)").matches &
                window.matchMedia("(min-width: 500px)").matches
                  ? el.userNickname.length > 10
                    ? el.userNickname.slice(0, 5) + "..."
                    : el.userNickname
                  : false}
                {window.matchMedia("(max-width: 500px)").matches &
                window.matchMedia("(min-width: 300px)").matches
                  ? el.userNickname.length > 7
                    ? el.userNickname.slice(0, 6) + "..."
                    : el.userNickname
                  : false} */}
              </p>
              <p className="leaderbord__result-pts">-</p>
              <p className="leaderbord__result-win">-</p>
              <p className="leaderbord__result-lose">-</p>
              <p className="leaderbord__result-lose">-</p>
              <p className="leaderbord__result-percent">
                {/* {(el.win / (el.win + el.lose)).toFixed(2)} */} -
              </p>
              <p className="leaderbord__result-flag">-</p>
            </li>
          )}
        </ul>
      </section>
    </div>
  );
};

export default LeaderBoard;
