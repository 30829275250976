import React, { useState, useEffect } from "react";
import PlayersContainer from "../../../components/players-container";

import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import SubmitButton from "../components/SubmitButton/SubmitButton";
import "./CreateRoster.css";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import NoImage from "../../../assets/no-image.png";

import { withRouter } from "react-router-dom";
import { isFieldEmpty, isSelectEmpty } from "../../../helpers";
import { toast } from "react-toastify";
import { data as dataCountry, initialErrors } from "./const";
import { API, API_ROUTER } from "../../../api";

import { LinearProgress } from "@material-ui/core";
import SelectRoster from "../../../components/UI/forms/select-roster";

const CreateRoster = (props) => {
  const { countriesList } = props;
  let id = useParams();
  const dispatch = useDispatch();
  const { discipline } = useSelector((state) => state.roster);
  const [errors, setErrors] = useState(initialErrors);
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [teamMembers, setTeamMembers] = useState();
  const [rosterName, setRosterName] = useState("");
  const [rosterCountry, setRosterCountry] = useState([]);

  const [countPlayers, setCountPlayers] = useState(0);
  const [teamRosterMembers, setTeamRosterMembers] = useState([]);
  const [languagesList, setLanguagesList] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  console.log(discipline);
  useEffect(() => {
    if (countriesList === undefined) {
      return;
    }
    API.request({
      ...API_ROUTER.teams.getMembersGame,
      pathKeys: {
        teamId: id.teamId,
        gameId: discipline?.game?.id,
      },
    })
      .then((res) => {
        setTeamMembers(res.members);
        setRequestSuccess(true);
      })
      .catch((err) => console.log(err));
  }, [id.teamId, countriesList]);
  useEffect(() => {
    if (countriesList === undefined) {
      return;
    }
    API.request({
      ...API_ROUTER.public.getLanguages,
    })
      .then((res) => {
        const keys = Object.keys(res);
        const values = Object.values(res);
        const list = [];
        keys.forEach((key, index) => {
          list.push({
            value: key,
            label: values[index],
          });
        });
        setLanguagesList(list);
        setRequestSuccess(true);
      })
      .catch((err) => console.log(err));
  }, [countriesList]);

  // если роль у юзера member то преобразую в player
  const newDataMember = JSON.parse(
    JSON.stringify(teamRosterMembers),
    function (key, value) {
      if ((key === "role") & (value === "owner")) {
        return "capitan";
      } else if ((key === "role") & (value === "member")) {
        return "player";
      } else {
        return value;
      }
    }
  );
  // владелец команды капитан подефолту
  let findCapitan = teamMembers?.find((cap) => {
    return cap.role === "owner";
  });
  const newObjCapitan = {};
  newObjCapitan.userId = findCapitan?.id;
  newObjCapitan.nickname = findCapitan?.nickname;
  newObjCapitan.role = "capitan";

  newDataMember.push(newObjCapitan);
  const newSelectedLanguages = [];
  const formatedLanguage = () => {
    selectedLanguage.map((el) => {
      newSelectedLanguages.push(el.value);
      return newSelectedLanguages;
    });
  };
  formatedLanguage();
  const closeChoise = (e) => {
    e.preventDefault();
    const newErrors = initialErrors;
    setErrors({ ...errors, ...newErrors });
    newErrors.name = isFieldEmpty(rosterName);
    newErrors.gameId = isFieldEmpty(discipline?.game?.id);
    newErrors.languages = isSelectEmpty(newSelectedLanguages);
    newErrors.country = isFieldEmpty(rosterCountry[0]?.value);
    newErrors.members = isSelectEmpty(newDataMember);

    if (Object.values(newErrors).some((value) => value.length)) {
      if (newErrors.name === "This field is required") {
        toast.error("please enter roster name");
      }
      if (newErrors.country === "This field is required") {
        toast.error("please enter roster country");
      }
      if (newErrors.languages === "This field is required") {
        toast.error("please enter roster languages");
      }
      if (newErrors.gameId === "This field is required") {
        toast.error("please enter roster discipline");
      }
      if (newErrors.members === "This field is required") {
        toast.error("please enter roster members");
      }
    }
    console.log(newErrors.members);
    if (!Object.values(newErrors).some((value) => value.length)) {
      const data = {
        name: rosterName,
        gameId: discipline?.game?.id,
        country: rosterCountry[0]?.value,
        languages: newSelectedLanguages,
        members: newDataMember,
      };

      API.request({
        ...API_ROUTER.roster.add,
        pathKeys: {
          teamId: id.teamId,
        },
        data,
      })

        .then(() => history.push(`/teams/team/${id.teamId}`))
        .catch((err) => toast.error(err.data && err.data.message));
    }
  };

  const history = useHistory();
  function showToast() {
    toast.success(
      "Roster includes players who have added discipline to their profile"
    );
  }
  const renderButton = () => {
    if (teamMembers) {
      return (
        <SubmitButton
          type="submit"
          buttonText={"Create roster"}
          disabled={true}
          clickFunction={closeChoise}
        />
      );
    } else {
      return (
        <SubmitButton
          type="submit"
          buttonText={
            "Roster shall include players who have added discipline to their profile"
          }
          disabled={false}
          // clickFunction={closeChoise}
        />
      );
    }
  };

  return requestSuccess ? (
    <div>
      <div className="create-roster">
        <h1 className="create-roster__title">Create Roster</h1>
        <form className="create-roster__form" action="">
          <section className="create-roster__form-discipline">
            <div className="form-discipline">
              <p className="form-discipline__title">Discipline</p>
            </div>
            {discipline ? (
              <ul className="games__list">
                <li className={"games__item-list"} key={discipline?.game.id}>
                  <img
                    className="games__item-list-image"
                    src={
                      discipline?.game.image ? discipline?.game.image : NoImage
                    }
                    alt={discipline?.game.title}
                    width="100"
                    height="100"
                  />

                  <p className="games__item-list-text">
                    {discipline?.game.title}
                  </p>
                </li>
              </ul>
            ) : (
              <>
                <div className="title-and-link__edit-roster-link title-and-link__edit-roster-link--disabled">
                  You have to choose
                  <button
                    className="title-and-link__edit-roster-link"
                    onClick={() =>
                      history.push(`/teams/team/${id.teamId}/roster/discipline`)
                    }
                  >
                    Discipline
                  </button>
                  before making a roster
                </div>
              </>
            )}
          </section>
          <section className="create-roster__roster-name">
            <h3 className="roster-name__title">Roster Name*</h3>
            <input
              className="roster-name__input"
              type="text"
              placeholder="Enter Roster Name"
              value={rosterName}
              onChange={(e) => setRosterName(e.target.value)}
            />
          </section>

          <section className="create-roster__language">
            <h3 className="language__title">Select The Speaking Language</h3>

            <article className="create-roster__language">
              <SelectRoster
                placeholder="Select Country..."
                clearable
                options={languagesList}
                multiple
                values={selectedLanguage}
                onChange={(e) => setSelectedLanguage(e)}
              />
            </article>
          </section>

          <section className="create-roster__language">
            <h3 className="language__title">Select Country</h3>

            <article className="create-roster__language">
              <SelectRoster
                required
                placeholder="Select Country..."
                clearable
                options={countriesList}
                values={rosterCountry}
                onChange={(e) => setRosterCountry(e, "country")}
              />
            </article>
          </section>
          <section className="create-roster__change-roster">
            <>
              <div className="change-roster__title-and-link">
                <p className="title-and-link__title">Roster</p>
                <i
                  className="icon icon-exclamation-mark"
                  onClick={() => showToast()}
                ></i>
              </div>
              Main players
              <div className="contentContainer">
                {teamMembers?.map((member) => (
                  <>
                    <PlayersContainer
                      key={member.id}
                      id={member.id}
                      name={member.nickname}
                      role={member.role}
                      title={member.title}
                      logo={member.avatar}
                      btn={true}
                      setCountPlayers={setCountPlayers}
                      countPlayers={countPlayers}
                      setTeamRosterMembers={setTeamRosterMembers}
                      teamRosterMembers={teamRosterMembers}
                      main={true}
                    />
                  </>
                ))}
              </div>
            </>
          </section>

          {renderButton()}
        </form>
      </div>
    </div>
  ) : (
    <>
      <LinearProgress />
      <div className="title-and-link__edit-roster-link title-and-link__edit-roster-link--disabled">
        You have to choose
        <button
          className="title-and-link__edit-roster-link"
          onClick={() =>
            history.push(`/teams/team/${id.teamId}/roster/discipline`)
          }
        >
          Discipline
        </button>
        before making a roster
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    countriesList: state.countriesList,

    userData: state.userData,
  };
};

export default withRouter(connect(mapStateToProps)(CreateRoster));
