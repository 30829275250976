import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useParams, useHistory } from "react-router-dom";
import { API, API_ROUTER } from "../../api";
import { FormattedMessage, injectIntl } from "react-intl";
import NoImage from "../../assets/no-image.png";
import { convertDate } from "../../helpers/dates-formatter";
import { ROUTER } from "../../config";
import GameItem from "./components/game-item";
//
import Button from "../../components/UI/buttons/buttons-login_register/button/Button";
import pubg_logo from "../../assets/images/pubg.png";

export default function CupInformation(props) {
  let { id } = useParams();
  let { tournament } = props;
  const [userData, setUserData] = useState(null);
  const [answerFromServer, setAnswerFromServer] = useState("");
  const [isUserDataParicipant, setIsUserDataParicipant] = useState();

  const rewardsColor = {
    1: "#ffc200",
    2: "#d1d3d4",
    3: "#c49a6c",
  };
  const history = useHistory();

  useEffect(() => {
    API.request({
      ...API_ROUTER.user.getMyData,
    })
      .then((res) => {
        setUserData(res);
      })
      .catch((err) => console.log(err));
  }, []);

  const goToUrl = (url) => {
    history.push(url);
  };
  useEffect(() => {
    if (userData?.id) {
      API.request({
        ...API_ROUTER.tournaments.getUserCurrentTournament,
        pathKeys: {
          userId: userData?.id,
          tournamentId: tournament?.id,
        },
      })
        .then((res) => {
          setIsUserDataParicipant(res);
        })
        .catch((err) => console.log(err));
    }
  }, [userData?.id, tournament?.id]);

  const submitTeamRoster = (tournamentId, userInfo) => {
    API.request({
      ...API_ROUTER.tournaments.postTeamRoster,
      pathKeys: {
        tournamentId: tournamentId,
      },
      data: {
        user: {
          id: userInfo.id,
          nickname: userInfo.nickname,
        },
        team: null,
      },
    })
      .then((res) => {
        setAnswerFromServer("successfully");
      })
      .catch((err) => setAnswerFromServer(err.message));
  };

  const checkParticipationTypeData = (type, fn) => {
    type === "user"
      ? submitTeamRoster(id, userData)
      : fn(`/battleCup/${tournament?.id}/registrationTournament`);
  };

  return (
    <StyledCupInformation>
      {tournament?.registrationStartedAt &&
        tournament?.registrationEndedAt && (
          <section className="reg__period">
            <div>
              <i className="icon icon-clock" />
            </div>
            <div>
              <p>
                <FormattedMessage id="battlecup.information.registrationPeriod" />
              </p>
              <p>
                {convertDate(tournament?.registrationStartedAt)} -{" "}
                {convertDate(tournament?.registrationEndedAt)}
              </p>
            </div>
          </section>
        )}{" "}
      {/* description */}
      {tournament?.details?.description ? (
        <section className="tour__details">
          <div>
            {tournament?.media?.logo ? (
              <img src={tournament?.media?.logo || NoImage} alt="pubg_logo" />
            ) : (
              false
            )}
          </div>
          <div>
            <h1>
              <FormattedMessage id="battlecup.information.tournamentDetails" />
            </h1>
            {tournament?.details?.description ? (
              <p>{tournament?.details?.description}</p>
            ) : (
              <p>
                Welcome.
                <br />
                This tournament hosted by Era.
              </p>
            )}
          </div>
        </section>
      ) : (
        false
      )}
      {/* organizer */}
      {tournament?.details?.organizerName ? (
        <section className="tour__details">
          <div>
            {tournament?.media?.logo ? (
              <img src={tournament?.media?.logo || NoImage} alt="pubg_logo" />
            ) : (
              false
            )}
          </div>

          <div>
            <h1> {tournament?.details?.organizerName}</h1>
          </div>
        </section>
      ) : (
        false
      )}
      {/* PRIZE FOUND */}
      {tournament?.details?.prizeFund ? (
        <section className="cash__prize">
          <h1>
            <FormattedMessage id="battlecup.information.prizeDetails" />
          </h1>
          {tournament?.details?.prizeFund && tournament?.details?.prizeFund}
          {/* <ul>
            {tournament?.details?.moneyRewardDetails?.map((item) => (
              <li>
                {item.place || "0"} -{" "}
                <span
                  style={{
                    color: rewardsColor[item?.place?.split("")[0]] || "",
                  }}
                >
                  {item.reward || "00"}
                </span>
              </li>
            ))}
          </ul> */}
        </section>
      ) : (
        false
      )}
      <section className="tour__game game-and-format">
        <h1>GAME AND FORMAT</h1>
        <ul>
          <li>
            <GameItem
              logo={tournament?.game?.logo}
              title={tournament?.game?.title}
              size={tournament?.size}
            />
            <li>Participant Limit: {tournament?.participantLimit}</li>
          </li>
        </ul>
      </section>
      {/* CONTACTS */}
      {tournament?.details?.contacts?.length ? (
        <section className="tour__rules contacts">
          <h1>CONTACTS</h1>
          <ul>
            {tournament?.details?.contacts?.map((el) => {
              return (
                <li>
                  {el?.name ? el?.name : false}{" "}
                  <a style={{ color: "aqua" }}>
                    {el?.title ? ":" + el?.title : false}
                  </a>
                </li>
              );
            })}
          </ul>
        </section>
      ) : (
        false
      )}
      {/* PARTNER LOGO */}
      {tournament?.media?.partnerLogo?.length ? (
        <div className="events__about-wrapper-tournaments partners">
          <section className="events__about-tournaments">
            <h4 className="events__about-title events__about-title--organizer">
              partners
            </h4>
            <article
              className="events__organizer-team"
              style={{ alignItems: "normal" }}
            >
              <div
                className="events__games-container"
                style={{ minHeight: "0" }}
              >
                {tournament?.media?.partnerLogo?.map((el) => (
                  <img
                    className="organizer__image"
                    src={el}
                    alt=""
                    style={{ width: 100, height: 100, borderRadius: "10px" }}
                  />
                ))}
              </div>
            </article>
          </section>
        </div>
      ) : (
        false
      )}
      {/* SPONSOR LOGO */}
      {tournament?.media?.organizerLogo?.length ? (
        <div className="events__about-wrapper-tournaments sponsors">
          <section className="events__about-tournaments">
            <h4 className="events__about-title events__about-title--organizer">
              Sponsors
            </h4>
            <article
              className="events__organizer-team"
              style={{ alignItems: "normal" }}
            >
              <div
                className="events__games-container"
                style={{ minHeight: "0" }}
              >
                {tournament?.media.sponsorLogo?.map((el) => (
                  <img
                    className="organizer__image"
                    src={el}
                    alt=""
                    style={{ width: 100, height: 100, borderRadius: "10px" }}
                  />
                ))}
              </div>
            </article>
          </section>
        </div>
      ) : (
        false
      )}
      {/* ORGANIZATOR LOGO */}
      {tournament?.media?.organizerLogo?.length ? (
        <div className="events__about-wrapper-tournaments sponsors">
          <section className="events__about-tournaments">
            <h4 className="events__about-title events__about-title--organizer">
              organizer
            </h4>
            <article
              className="events__organizer-team"
              style={{ alignItems: "normal" }}
            >
              <div
                className="events__games-container"
                style={{ minHeight: "0" }}
              >
                {tournament?.media.organizerLogo?.map((el) => (
                  <img
                    className="organizer__image"
                    src={el}
                    alt=""
                    style={{ width: 100, height: 100, borderRadius: "10px" }}
                  />
                ))}
              </div>
            </article>
          </section>
        </div>
      ) : (
        false
      )}
      {/* Registration */}
      {isUserDataParicipant?.team ||
      isUserDataParicipant?.teamRoster ||
      isUserDataParicipant?.user ? (
        <section className="btn__wrapper">
          <div>
            <Button type="cupbottom">You already registered</Button>
          </div>
        </section>
      ) : tournament?.status === "registration" ? (
        <section className="btn__wrapper">
          {answerFromServer === "successfully" ? (
            <div>You are registration successfully</div>
          ) : (
            <div
              onClick={() =>
                checkParticipationTypeData(props.participationTypeData, goToUrl)
              }
              /*  style={{ textDecoration: "none" }} */
              /* to={props.participationTypeData === 'user' ? `/battleCup/${tournament?.id}/registrationTournament` : false} */
            >
              <Button active type="cupbottom">
                <FormattedMessage id="battlecup.information.registrationButton" />
              </Button>
            </div>
          )}
        </section>
      ) : (
        false
      )}
    </StyledCupInformation>
  );
}

const StyledCupInformation = styled.div`
  padding: 25px;

  & > section {
    &.reg__period {
      background-color: #333333;
      display: -ms-grid;
      display: grid;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      grid-template-columns: 50px auto;
      gap: 16px;
      padding: 14px 0 14px 14px;
      border-radius: 16px;
      margin-bottom: 16px;

      & > div {
        &:first-of-type {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
        }
        & > i {
          width: 48px;
          height: 48px;
          background-color: #a49346;
        }

        & > p {
          color: white;
          font-size: 16px;
          font-weight: bold;
          font-style: normal;
          letter-spacing: -0.61px;
          line-height: normal;
          padding: 2px 0;

          &:first-of-type {
            border-bottom: 1px solid grey;
          }
        }
      }
    }

    &.tour__details {
      margin-bottom: 16px;
      background-color: #333333;

      padding: 11px;
      border-radius: 10px;
      display: -ms-grid;
      display: grid;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      grid-template-columns: 65px auto;
      gap: 16px;

      & > div {
        &:first-of-type {
          & > img {
            width: 62px;
            height: auto;
            border-radius: 10px;
          }
        }

        &:last-of-type {
          text-align: center;

          & > h1 {
            font-size: 16px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: -0.61px;
            line-height: normal;
            border-bottom: 1px solid grey;
            padding-bottom: 5px;
            margin: 0 auto;
            margin-bottom: 5px;
          }

          & > p {
            font-size: 13px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.51px;
            line-height: normal;
          }
        }
      }
    }

    &.tour__rules {
      background-color: #333333;
      ${"" /* TODO color  */}
      ${"" /* background: rgb(43, 36, 74); */}
      border-radius: 10px;
      padding: 10px 18px;
      margin-bottom: 16px;

      & > h1 {
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: -0.61px;
        line-height: normal;
        border-bottom: 1px solid grey;
        padding-bottom: 5px;
        margin-bottom: 5px;
      }

      & > ul {
        li {
          font-size: 15px;
          font-weight: 300;
          font-style: normal;
          letter-spacing: -0.51px;
          line-height: normal;
          margin: 10px;
        }
      }

      & > a {
        color: #f7a01d;
        font-size: 11px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.41px;
        line-height: normal;
        display: block;
        width: max-content;
        margin-left: auto;
        margin-top: 5px;
      }
    }

    &.cash__prize {
      background-color: #333333;
      ${"" /* TODO color  */}
      ${"" /* background: rgb(43, 36, 74); */}
      border-radius: 10px;
      padding: 10px 18px;
      margin-bottom: 25px;

      & > h1 {
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: -0.61px;
        line-height: normal;
        border-bottom: 1px solid grey;
        padding-bottom: 5px;
        margin-bottom: 5px;
      }

      & > ul {
        li {
          font-size: 13px;
          font-weight: normal;
          font-style: normal;
          letter-spacing: -0.51px;
          line-height: 16px;
        }
      }
    }
    &.tour__game {
      background-color: #333333;
      ${"" /* TODO color  */}
      ${"" /* background: rgb(43, 36, 74); */}
      border-radius: 10px;
      padding: 10px 18px;
      margin-bottom: 16px;

      & > h1 {
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: -0.61px;
        line-height: normal;
        border-bottom: 1px solid grey;
        padding-bottom: 5px;
        margin-bottom: 5px;
      }
    }

    &.btn__wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  }
  .events__about-wrapper-tournaments {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 24px;
  }

  .events__about-tournaments {
    padding: 20px;
    margin: 0 auto;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
  }
  .events__about-title--organizer {
    margin-top: 24px;
    margin-bottom: 12px;
  }
  .events__about-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.87);
    margin-bottom: 12px;
    border-bottom: 1px solid gray;
  }
  .events__organizer-team {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px;
    /* background: #212121; */
    /* TODO color */
    border: 3px solid #212121;
    border-radius: 26px;
  }

  .events__organizer-team-container {
    display: flex;
  }
  .organizer__image {
    margin-right: 16px;
  }
  .events__games-container {
    padding: 5px 5px;
    display: flex;
    gap: 30px;
    overflow-y: auto;

    min-height: 150px;
  }
  .events__games-container::-webkit-scrollbar {
    height: 0;
  }
`;
