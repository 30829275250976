import est from "../../../assets/svg/est.svg";
import ger from "../../../assets/svg/ger.svg";
import usa from "../../../assets/svg/usa.svg";

export const data = [
  {
    country: "Estonia",
  },
  {
    country: "Germany",
  },
  {
    country: "USA",
  },
];
export const languageList = [
  {
    id: 0,
    language: "Estonian",
    img: est,
  },
  {
    id: 1,
    language: "Germany",
    img: ger,
  },
  {
    id: 2,
    language: "English",
    img: usa,
  },
];
export const initialErrors = {
  gameId: "",
  name: "",
  country: "",
  languages: [],
  members: [],
};
