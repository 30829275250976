import React, { useState, useEffect } from "react";
import defaultLogo from "../../assets/avatars/avatar.jpg";
import s from "./playersContainer.module.scss";
import getTeamMembers from "../../helpers/team/teamMembers";
import PlayersContainer from "../../components/players-container";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { API, API_ROUTER } from "../../api";
import RosterContainer from "../../components/roster-container";
const TeamContainer = (props) => {
  const [btnCheck, setBtnCheck] = useState(false);
  const [teamId, setTeamId] = useState();
  const [gameId, setGameId] = useState();
  const [rosters, setRosters] = useState();
  const [singleRoster, setSingleRoster] = useState();

  const [flag, setFlag] = useState();
  // убрать галочку списка если открыт ростер
  const [requestSuccess, setRequestSuccess] = useState(false);
  useEffect(() => {
    API.request({
      ...API_ROUTER.roster.getAllRosters,
      pathKeys: {
        teamId: teamId,
      },
    })
      .then((res) => {
        setRosters(res.rosters);
        setRequestSuccess(true);
      })
      .catch((err) => console.log(err));
  }, [teamId]);

  const changeCountPlayers = (boolean, id, name, gameId) => {
    setBtnCheck(!boolean);
    setTeamId(id);
    setGameId(gameId);
    props.setTeamId(id);
    props.setTeamName(name);
    // reset count if close team
    if (btnCheck === true) {
      props.setCountPlayers(0);
      props.setTeamRosterMembers([]);
    }
    props.setCurrentTeam([props.el]);
    props.setFlag(boolean);
  };
  const history = useHistory();
  function goToUrl(url) {
    history.push(url);
  }
  console.log(flag);
  return (
    <>
      <div className={s.playersContainer}>
        <div className={s.playerCard}>
          <div
            className={s.leftContainer}
            onClick={() => {
              goToUrl(`/teams/team/${props.id}`);
            }}
          >
            <div className={s.imgContainer}>
              <img src={props.logo || defaultLogo} alt="logo" />
            </div>
            <div className={s.textContainer}>
              <div className={s.teamName}>{props.name}</div>
            </div>
          </div>

          {props.btn ? (
            <div
              className={
                btnCheck ? `${s.checkMark} ${s.btn}` : `${s.plus} ${s.btn}`
              }
              onClick={() => {
                changeCountPlayers(
                  btnCheck,
                  props.id,
                  props.name,
                  props.gameId
                );
              }}
            ></div>
          ) : (
            <div className={s.plusMini}>+</div>
          )}
        </div>
      </div>

      {btnCheck ? (
        requestSuccess ? (
          <>
            <div className={s.playerRow}>
              <div className={s.rowTitle}>Choose Roster</div>
            </div>
            {flag !== false
              ? rosters?.map((el) => (
                  <RosterContainer
                    key={el.id}
                    id={el.id}
                    rosterName={el.rosterName}
                    gameLogo={el.gameLogo}
                    rosterMembers={el.rosterMembers}
                    setFlag={setFlag}
                    setSingleRoster={setSingleRoster}
                    setPlayersCount={props.setPlayersCount}
                    el={el}
                    setRosterId={props.setRosterId}
                  />
                ))
              : singleRoster?.map((el) => (
                  <RosterContainer
                    key={el.id}
                    id={el.id}
                    rosterName={el.rosterName}
                    gameLogo={el.gameLogo}
                    rosterMembers={el.rosterMembers}
                    setFlag={setFlag}
                    setSingleRoster={setSingleRoster}
                    setPlayersCount={props.setPlayersCount}
                    setRosterId={props.setRosterId}
                  />
                ))}
          </>
        ) : (
          <CircularProgress />
        )
      ) : (
        false
      )}
    </>
  );
};
export default TeamContainer;
