import React, { useState } from "react";
import defaultLogo from "../../assets/avatars/avatar.jpg";
import s from "./playersContainer.module.scss";
import { toast } from "react-toastify";

const PlayersContainer = (props) => {
  const [btnCheck, setBtnCheck] = useState(false);

  const changeCountPlayers = (boolean, members, id) => {
    setBtnCheck(!boolean);
    console.log(members);
    props.setPlayersCount(members.members.length);
    props.setSingleRoster([props.el]);
    props.setFlag(boolean);
    props.setRosterId(id);
    if (btnCheck === true) {
      props.setPlayersCount(0);
    }
  };

  return (
    <>
      <div className={s.playersContainer}>
        <div className={s.playerCard}>
          <div className={s.leftContainer}>
            <div className={s.imgContainer}>
              <img src={props.gameLogo || defaultLogo} alt="logo" />
            </div>
            <div className={s.textContainer}>
              <div className={s.teamName}>{props.rosterName}</div>
              <div className={s.subtitle}>{props.title}</div>
            </div>
          </div>
          <div
            className={
              btnCheck ? `${s.checkMark} ${s.btn}` : `${s.plus} ${s.btn}`
            }
            onClick={() => {
              changeCountPlayers(btnCheck, props.rosterMembers, props.id);
            }}
          ></div>
        </div>
      </div>
      {btnCheck ? (
        <>
          {props.rosterMembers?.members.map((el) => (
            <div className={s.playersContainer}>
              <div className={s.playerCard}>
                <div className={s.leftContainer}>
                  <div className={s.imgContainer}>
                    <img src={el.avatar || defaultLogo} alt="logo" />
                  </div>
                  <div className={s.textContainer}>
                    <div className={s.teamName}>{el.nickname}</div>
                    <div className={s.subtitle}>{el.role}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        false
      )}
    </>
  );
};
export default PlayersContainer;
