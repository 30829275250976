import React, { useState, useEffect } from "react";
import s from "./inviteTeam.module.scss";
import { API, API_ROUTER } from "../../api";
import defaultAvatar from "../../assets/avatars/avatar.jpg";
import RosterMembersItem from "../../components/rosterMember";
import { useParams } from "react-router-dom";
import { getUserData } from "../../redux/actions";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import store from "../../redux/store";
import { useHistory } from "react-router-dom";

const RosterMembers = (props) => {
  const [members, setMembers] = useState();
  const [inputText, setInputText] = useState("");
  const [err, setErrt] = useState(false);
  const [errText, setTextErr] = useState("");
  const [team, setTeam] = useState(null);
  const [checkEditSuccess, setCheckEditSuccess] = useState();

  const [answerFromServer, setAnswerFromServer] = useState(null);
  const { id } = useParams();
  const { teamId } = useParams();

  console.log(checkEditSuccess?.hasAccessEdit);
  useEffect(() => {
    API.request({
      ...API_ROUTER.roster.getRoster,
      pathKeys: {
        rosterId: id,
      },
    })
      .then((res) => {
        setMembers(res?.rosterMembers?.members);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    API.request({
      ...API_ROUTER.teams.getTeamDetails,
      pathKeys: {
        teamId: teamId,
      },
    })
      .then((res) => {
        setCheckEditSuccess(res);
      })
      .catch((err) => console.log(err));
  }, []);
  console.log(members);
  const history = useHistory();

  return (
    <div className={s.containerInviteTeam}>
      <div className={s.contantContainer}>
        <div className={s.messageContainer}>
          {err && <div className={s.err}>{errText}</div>}
          {answerFromServer && <div>{answerFromServer}</div>}
        </div>

        <div className={s.teamMembersContainer}>
          <div className={s.teamMembersTitle}>
            <div className={s.titleMembers}>Team Members</div>
            <button
              className="title-and-link__edit-roster-link"
              onClick={() =>
                history.push(`/teams/team/${teamId}/roster/${id}/edit`)
              }
            >
              Edit
            </button>
          </div>

          <div className={s.playerContainers}>
            {members?.map((el) => (
              <RosterMembersItem
                key={el.id}
                id={el.id}
                role={el.role}
                avatar={el.avatar}
                nickname={el.nickname}
                ownerTeam={checkEditSuccess?.hasAccessEdit}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserData: () => dispatch(getUserData()),
    userOnline: () => dispatch(userOnline()),
  };
};
export default injectIntl(
  withRouter(connect(() => {}, mapDispatchToProps)(RosterMembers))
);
