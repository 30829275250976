import { API, API_ROUTER } from "../../api";

const getCommunityTournaments = (teamId) => {
  const params = {
    ...API_ROUTER.teams.getMembers,
    pathKeys: {
      teamId,
    },
  };
  return API.request(params);
};

export default getCommunityTournaments;
