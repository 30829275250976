import React, { useState } from "react";
import defaultLogo from "../../assets/avatars/avatar.jpg";
import s from "./playersContainer.module.scss";
import { toast } from "react-toastify";

const PlayersContainer = (props) => {
  const [btnCheck, setBtnCheck] = useState(false);
  const [btnCheckEdit, setBtnCheckEdit] = useState(true);
  const [btnCheckReserve, setBtnCheckEditReserve] = useState(false);
  function showToast() {
    toast.success("The Captain cannot be changed");
  }
  const changeCountPlayers = (boolean, id, name, role) => {
    setBtnCheck(!boolean);
    if (btnCheck) {
      props.setCountPlayers(props.countPlayers - 1);
      const newArray = props.teamRosterMembers.filter((el) => el.userId !== id);
      // console.log(newArray, "delete");
      props.setTeamRosterMembers(newArray);
    } else {
      props.setCountPlayers(props.countPlayers + 1);
      const newObj = {};
      newObj.userId = id;
      newObj.nickname = name;
      newObj.role = role;
      const newArray = JSON.parse(JSON.stringify(props.teamRosterMembers));

      newArray.push(newObj);

      props.setTeamRosterMembers(newArray);
      // console.log(newArray, "ADD");
    }
  };
  const changeCountPlayersReserve = (boolean, id, name, role) => {
    setBtnCheckEditReserve(!boolean);
    if (btnCheckReserve) {
      props.setCountPlayers(props.countPlayers - 1);
      const newArray = props.teamRosterMembers.filter((el) => el.userId !== id);
      console.log(newArray, "delete");
      props.setTeamRosterMembers(newArray);
    } else {
      props.setCountPlayers(props.countPlayers + 1);
      const newObj = {};
      newObj.userId = id;
      newObj.nickname = name;
      newObj.role = "reserve";

      const newArray = JSON.parse(JSON.stringify(props.teamRosterMembers));
      newArray.push(newObj);
      props.setTeamRosterMembers(newArray);
      console.log(newArray, "ADD");
    }
  };

  const changeCountPlayersEdit = (boolean, editId, name, role) => {
    setBtnCheckEdit(!boolean);
    if (btnCheckEdit) {
      props.setCountPlayers(props.countPlayers - 1);
      const newArray = props.teamRosterMembers.filter(
        (el) => el.userId !== editId
      );
      console.log(newArray, "delete");
      props.setTeamRosterMembers(newArray);
    } else {
      props.setCountPlayers(props.countPlayers + 1);
      const newObj = {};
      newObj.userId = editId;
      newObj.nickname = name;
      newObj.role = role === "capitan" ? "capitan" : "player";

      const newArray = JSON.parse(JSON.stringify(props.teamRosterMembers));
      newArray.push(newObj);
      console.log(newArray, "add");

      props.setTeamRosterMembers(newArray);
    }
  };
  const changeCountPlayersReserveEdit = (boolean, editId, name, role) => {
    setBtnCheckEditReserve(!boolean);
    if (btnCheckReserve) {
      props.setCountPlayers(props.countPlayers - 1);
      const newArray = props.teamRosterMembers.filter(
        (el) => el.userId !== editId
      );
      console.log(newArray, "delete");
      props.setTeamRosterMembers(newArray);
    } else {
      props.setCountPlayers(props.countPlayers + 1);
      const newObj = {};
      newObj.userId = editId;
      newObj.nickname = name;
      newObj.role = "reserve";

      const newArray = JSON.parse(JSON.stringify(props.teamRosterMembers));
      newArray.push(newObj);
      console.log(newArray, "add");

      props.setTeamRosterMembers(newArray);
    }
  };
  return (
    <div className={s.playersContainer}>
      <div className={s.playerCard}>
        <div className={s.leftContainer}>
          <div className={s.imgContainer}>
            <img src={props.logo || defaultLogo} alt="logo" />
          </div>
          <div className={s.textContainer}>
            <div className={s.teamName}>{props.name}</div>
            <div className={s.subtitle}>{props.title}</div>
          </div>
        </div>
        {(props.role !== "capitan") & (props.role !== "owner") ? (
          props.edit ? (
            <div className={s.btn__group}>
              {!btnCheckReserve && (
                <div
                  className={
                    btnCheckEdit
                      ? `${s.checkMark} ${s.btn}`
                      : `${s.plus} ${s.btn}`
                  }
                  onClick={() =>
                    changeCountPlayersEdit(
                      btnCheckEdit,
                      props.editId,
                      props.name,
                      props.role
                    )
                  }
                >
                  {!btnCheckEdit ? "New" : "Curr"}
                </div>
              )}
              {!btnCheckEdit && (
                <div
                  className={
                    btnCheckReserve
                      ? `${s.checkMark} ${s.btn}`
                      : `${s.plus} ${s.btn}`
                  }
                  onClick={() =>
                    changeCountPlayersReserveEdit(
                      btnCheckReserve,
                      props.editId,
                      props.name,
                      props.role
                    )
                  }
                >
                  Res
                </div>
              )}
            </div>
          ) : (
            <div className={s.btn__group}>
              {!btnCheckReserve && (
                <div
                  className={
                    btnCheck ? `${s.checkMark} ${s.btn}` : `${s.plus} ${s.btn}`
                  }
                  onClick={() =>
                    changeCountPlayers(
                      btnCheck,
                      props.id,
                      props.name,
                      props.role
                    )
                  }
                >
                  New
                </div>
              )}
              {!btnCheck && (
                <div
                  className={
                    btnCheckReserve
                      ? `${s.checkMark} ${s.btn}`
                      : `${s.plus} ${s.btn}`
                  }
                  onClick={() =>
                    changeCountPlayersReserve(
                      btnCheckReserve,
                      props.id,
                      props.name,
                      props.role
                    )
                  }
                >
                  Res
                </div>
              )}
            </div>
          )
        ) : (
          <i
            className="icon icon-exclamation-mark"
            onClick={() => showToast()}
          ></i>
        )}
      </div>
    </div>
  );
};
export default PlayersContainer;
