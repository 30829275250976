import React from "react";

const dots = () => (
    <svg width="149" height="91" viewBox="0 0 149 91" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M146.2 90.9002C147.636 90.9002 148.8 89.7361 148.8 88.3002C148.8 86.8643 147.636 85.7002 146.2 85.7002C144.764 85.7002 143.6 86.8643 143.6 88.3002C143.6 89.7361 144.764 90.9002 146.2 90.9002Z" fill="#D5CBFF"/>
            <path d="M122.3 90.9002C123.736 90.9002 124.9 89.7361 124.9 88.3002C124.9 86.8643 123.736 85.7002 122.3 85.7002C120.864 85.7002 119.7 86.8643 119.7 88.3002C119.7 89.7361 120.864 90.9002 122.3 90.9002Z" fill="#D5CBFF"/>
            <path d="M98.3998 90.9002C99.8357 90.9002 101 89.7361 101 88.3002C101 86.8643 99.8357 85.7002 98.3998 85.7002C96.9639 85.7002 95.7998 86.8643 95.7998 88.3002C95.7998 89.7361 96.9639 90.9002 98.3998 90.9002Z" fill="#D5CBFF"/>
            <path d="M74.3998 90.9002C75.8998 90.9002 76.9998 89.7002 76.9998 88.3002C76.9998 86.8002 75.7998 85.7002 74.3998 85.7002C72.8998 85.7002 71.7998 86.9002 71.7998 88.3002C71.7998 89.7002 72.9998 90.9002 74.3998 90.9002Z" fill="#D5CBFF"/>
            <path d="M50.4999 90.9002C51.9358 90.9002 53.0999 89.7361 53.0999 88.3002C53.0999 86.8643 51.9358 85.7002 50.4999 85.7002C49.064 85.7002 47.8999 86.8643 47.8999 88.3002C47.8999 89.7361 49.064 90.9002 50.4999 90.9002Z" fill="#D5CBFF"/>
            <path d="M26.6 90.9002C28.0359 90.9002 29.2 89.7361 29.2 88.3002C29.2 86.8643 28.0359 85.7002 26.6 85.7002C25.1641 85.7002 24 86.8643 24 88.3002C24 89.7361 25.1641 90.9002 26.6 90.9002Z" fill="#D5CBFF"/>
            <path d="M2.6 90.9002C4.03594 90.9002 5.2 89.7361 5.2 88.3002C5.2 86.8643 4.03594 85.7002 2.6 85.7002C1.16406 85.7002 0 86.8643 0 88.3002C0 89.7361 1.16406 90.9002 2.6 90.9002Z" fill="#D5CBFF"/>
            <path d="M146.2 69.4998C147.636 69.4998 148.8 68.3357 148.8 66.8998C148.8 65.4639 147.636 64.2998 146.2 64.2998C144.764 64.2998 143.6 65.4639 143.6 66.8998C143.6 68.3357 144.764 69.4998 146.2 69.4998Z" fill="#D5CBFF"/>
            <path d="M122.3 69.4998C123.736 69.4998 124.9 68.3357 124.9 66.8998C124.9 65.4639 123.736 64.2998 122.3 64.2998C120.864 64.2998 119.7 65.4639 119.7 66.8998C119.7 68.3357 120.864 69.4998 122.3 69.4998Z" fill="#D5CBFF"/>
            <path d="M98.3998 69.4998C99.8357 69.4998 101 68.3357 101 66.8998C101 65.4639 99.8357 64.2998 98.3998 64.2998C96.9639 64.2998 95.7998 65.4639 95.7998 66.8998C95.7998 68.3357 96.9639 69.4998 98.3998 69.4998Z" fill="#D5CBFF"/>
            <path d="M74.3998 69.4998C75.8998 69.4998 76.9998 68.2998 76.9998 66.8998C76.9998 65.3998 75.7998 64.2998 74.3998 64.2998C72.8998 64.2998 71.7998 65.4998 71.7998 66.8998C71.7998 68.2998 72.9998 69.4998 74.3998 69.4998Z" fill="#D5CBFF"/>
            <path d="M50.4999 69.4998C51.9358 69.4998 53.0999 68.3357 53.0999 66.8998C53.0999 65.4639 51.9358 64.2998 50.4999 64.2998C49.064 64.2998 47.8999 65.4639 47.8999 66.8998C47.8999 68.3357 49.064 69.4998 50.4999 69.4998Z" fill="#D5CBFF"/>
            <path d="M26.6 69.4998C28.0359 69.4998 29.2 68.3357 29.2 66.8998C29.2 65.4639 28.0359 64.2998 26.6 64.2998C25.1641 64.2998 24 65.4639 24 66.8998C24 68.3357 25.1641 69.4998 26.6 69.4998Z" fill="#D5CBFF"/>
            <path d="M2.6 69.4998C4.03594 69.4998 5.2 68.3357 5.2 66.8998C5.2 65.4639 4.03594 64.2998 2.6 64.2998C1.16406 64.2998 0 65.4639 0 66.8998C0 68.3357 1.16406 69.4998 2.6 69.4998Z" fill="#D5CBFF"/>
            <path d="M146.2 48.0999C147.636 48.0999 148.8 46.9358 148.8 45.4999C148.8 44.064 147.636 42.8999 146.2 42.8999C144.764 42.8999 143.6 44.064 143.6 45.4999C143.6 46.9358 144.764 48.0999 146.2 48.0999Z" fill="#D5CBFF"/>
            <path d="M122.3 48.0999C123.736 48.0999 124.9 46.9358 124.9 45.4999C124.9 44.064 123.736 42.8999 122.3 42.8999C120.864 42.8999 119.7 44.064 119.7 45.4999C119.7 46.9358 120.864 48.0999 122.3 48.0999Z" fill="#D5CBFF"/>
            <path d="M98.3998 48.0999C99.8357 48.0999 101 46.9358 101 45.4999C101 44.064 99.8357 42.8999 98.3998 42.8999C96.9639 42.8999 95.7998 44.064 95.7998 45.4999C95.7998 46.9358 96.9639 48.0999 98.3998 48.0999Z" fill="#D5CBFF"/>
            <path d="M74.3998 48.0999C75.8998 48.0999 76.9998 46.8999 76.9998 45.4999C76.9998 43.9999 75.7998 42.8999 74.3998 42.8999C72.8998 42.8999 71.7998 44.0999 71.7998 45.4999C71.7998 46.8999 72.9998 48.0999 74.3998 48.0999Z" fill="#D5CBFF"/>
            <path d="M50.4999 48.0999C51.9358 48.0999 53.0999 46.9358 53.0999 45.4999C53.0999 44.064 51.9358 42.8999 50.4999 42.8999C49.064 42.8999 47.8999 44.064 47.8999 45.4999C47.8999 46.9358 49.064 48.0999 50.4999 48.0999Z" fill="#D5CBFF"/>
            <path d="M26.6 48.0999C28.0359 48.0999 29.2 46.9358 29.2 45.4999C29.2 44.064 28.0359 42.8999 26.6 42.8999C25.1641 42.8999 24 44.064 24 45.4999C24 46.9358 25.1641 48.0999 26.6 48.0999Z" fill="#D5CBFF"/>
            <path d="M2.6 48.0999C4.03594 48.0999 5.2 46.9358 5.2 45.4999C5.2 44.064 4.03594 42.8999 2.6 42.8999C1.16406 42.8999 0 44.064 0 45.4999C0 46.9358 1.16406 48.0999 2.6 48.0999Z" fill="#D5CBFF"/>
            <path d="M146.2 26.5999C147.636 26.5999 148.8 25.4358 148.8 23.9999C148.8 22.564 147.636 21.3999 146.2 21.3999C144.764 21.3999 143.6 22.564 143.6 23.9999C143.6 25.4358 144.764 26.5999 146.2 26.5999Z" fill="#D5CBFF"/>
            <path d="M122.3 26.5999C123.736 26.5999 124.9 25.4358 124.9 23.9999C124.9 22.564 123.736 21.3999 122.3 21.3999C120.864 21.3999 119.7 22.564 119.7 23.9999C119.7 25.4358 120.864 26.5999 122.3 26.5999Z" fill="#D5CBFF"/>
            <path d="M98.3998 26.5999C99.8357 26.5999 101 25.4358 101 23.9999C101 22.564 99.8357 21.3999 98.3998 21.3999C96.9639 21.3999 95.7998 22.564 95.7998 23.9999C95.7998 25.4358 96.9639 26.5999 98.3998 26.5999Z" fill="#D5CBFF"/>
            <path d="M74.3998 26.7C75.8998 26.7 76.9998 25.5 76.9998 24.1C76.9998 22.6 75.7998 21.5 74.3998 21.5C72.8998 21.5 71.7998 22.7 71.7998 24.1C71.7998 25.5 72.9998 26.7 74.3998 26.7Z" fill="#D5CBFF"/>
            <path d="M50.4999 26.5999C51.9358 26.5999 53.0999 25.4358 53.0999 23.9999C53.0999 22.564 51.9358 21.3999 50.4999 21.3999C49.064 21.3999 47.8999 22.564 47.8999 23.9999C47.8999 25.4358 49.064 26.5999 50.4999 26.5999Z" fill="#D5CBFF"/>
            <path d="M26.6 26.5999C28.0359 26.5999 29.2 25.4358 29.2 23.9999C29.2 22.564 28.0359 21.3999 26.6 21.3999C25.1641 21.3999 24 22.564 24 23.9999C24 25.4358 25.1641 26.5999 26.6 26.5999Z" fill="#D5CBFF"/>
            <path d="M2.6 26.5999C4.03594 26.5999 5.2 25.4358 5.2 23.9999C5.2 22.564 4.03594 21.3999 2.6 21.3999C1.16406 21.3999 0 22.564 0 23.9999C0 25.4358 1.16406 26.5999 2.6 26.5999Z" fill="#D5CBFF"/>
            <path d="M146.2 5.2C147.636 5.2 148.8 4.03594 148.8 2.6C148.8 1.16406 147.636 0 146.2 0C144.764 0 143.6 1.16406 143.6 2.6C143.6 4.03594 144.764 5.2 146.2 5.2Z" fill="#D5CBFF"/>
            <path d="M122.3 5.2C123.736 5.2 124.9 4.03594 124.9 2.6C124.9 1.16406 123.736 0 122.3 0C120.864 0 119.7 1.16406 119.7 2.6C119.7 4.03594 120.864 5.2 122.3 5.2Z" fill="#D5CBFF"/>
            <path d="M98.3998 5.2C99.8357 5.2 101 4.03594 101 2.6C101 1.16406 99.8357 0 98.3998 0C96.9639 0 95.7998 1.16406 95.7998 2.6C95.7998 4.03594 96.9639 5.2 98.3998 5.2Z" fill="#D5CBFF"/>
            <path d="M74.3998 5.3001C75.8998 5.3001 76.9998 4.1001 76.9998 2.7001C76.9998 1.2001 75.7998 0.100098 74.3998 0.100098C72.8998 0.100098 71.7998 1.3001 71.7998 2.7001C71.7998 4.1001 72.9998 5.3001 74.3998 5.3001Z" fill="#D5CBFF"/>
            <path d="M50.4999 5.2C51.9358 5.2 53.0999 4.03594 53.0999 2.6C53.0999 1.16406 51.9358 0 50.4999 0C49.064 0 47.8999 1.16406 47.8999 2.6C47.8999 4.03594 49.064 5.2 50.4999 5.2Z" fill="#D5CBFF"/>
            <path d="M26.6 5.2C28.0359 5.2 29.2 4.03594 29.2 2.6C29.2 1.16406 28.0359 0 26.6 0C25.1641 0 24 1.16406 24 2.6C24 4.03594 25.1641 5.2 26.6 5.2Z" fill="#D5CBFF"/>
            <path d="M2.6 5.2C4.03594 5.2 5.2 4.03594 5.2 2.6C5.2 1.16406 4.03594 0 2.6 0C1.16406 0 0 1.16406 0 2.6C0 4.03594 1.16406 5.2 2.6 5.2Z" fill="#D5CBFF"/>
    </svg>
)

export default dots