import React from "react";
import styled, { css } from "styled-components";
import NoImage from "../../../assets/no-image.png";

const imageWithPlaceHolder = (logo) => (
  <img
    src={logo ? logo : NoImage}
    alt="img"
    style={{ width: 90, height: 90, borderRadius: "24px" }}
  />
);
export default function Card({
  title,
  noTitle = "Title",
  logo,
  size,
  isComing = false,
}) {
  return (
    <div>
      <StyledCard isComing={isComing}>
        <section>{imageWithPlaceHolder(logo)}</section>
        <section>
          {title && (
            <p>{title?.length ? title + "-" + size : title || noTitle}</p>
          )}
        </section>
      </StyledCard>
    </div>
  );
}

const StyledCard = styled.div`
  width: max-content;

  & > section {
    ${
      "" /* .placeholderImage {
      max-width: 70%;
    } */
    }

    &:first-of-type {
      width: 92px;
      height: 92px;
      border-radius: 24px;
      border: 1px solid var(--icon);
      ${"" /* background-color: #414042; */}
      background-color: rgba(255, 255, 255, 0.05);
      ${"" /* TODO color */}
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    &:last-of-type {
      & > p {
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        letter-spacing: -0.41px;
        line-height: normal;
        margin-top: 4px;
        text-align: center;
      }
    }
  }

  ${({ isComing }) =>
    isComing &&
    css`
      opacity: 0.2;
    `}
`;
